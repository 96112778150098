import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const ModalLayout = ({ open, closeModal, children }) => {

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-50 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div onClick={closeModal} className="absolute top-5 right-5 md:top-10 md:right-10 cursor-pointer z-10">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 2.66667L21.3333 0L12 9.33333L2.66667 0L0 2.66667L9.33333 12L0 21.3333L2.66667 24L12 14.6667L21.3333 24L24 21.3333L14.6667 12L24 2.66667Z" fill="#54585D" />
                        </svg>
                    </div>
                    <div className="min-h-screen text-center max-h-full overflow-y-auto py-14 md:py-0 px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 overflow-visible bg-white transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="inline-block w-full text-left align-middle transition-all transform bg-white">
                                <div className="mx-auto w-full max-w-454 h-full flex items-center min-h-screen">
                                    { children }
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
export default ModalLayout
