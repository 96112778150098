import React, { useState } from 'react';

const Loader = ({ classProps = "loader ml-2" }) => {
    return (
        <span className={`${classProps}`}></span>
    )

};

export default Loader
