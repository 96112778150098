import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import * as Yup from "yup";
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';

const ProjectCreate = ({
  createProject,
  nextStep,
  clientProjectsResponseData,
  clientNameSearch,
  technologyResponseData,
  TechnologyNameSearch,
  projectInfoEdit,
  UpdateBasicProjectInfo,
  closeModal,
  projectId }) => {

  const navigate = useNavigate();

  const [client, setClient] = useState([]);
  const [startDate, setStartDate] = useState();
  const [clientName, setClientName] = useState("");
  const [technologyList, setTechnologyList] = useState([]);
  const [technology, setTechnology] = useState([]);
  const dispatch = useDispatch();

  const getClientList = async () => {
    await clientNameSearch();
  }

  useEffect(() => {
    getClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setClient(clientProjectsResponseData);
  }, [clientProjectsResponseData]);

  const getTechnologyList = async () => {
    await TechnologyNameSearch();
  }

  useEffect(() => {
    getTechnologyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTechnologyList(technologyResponseData);
  }, [technologyResponseData]);

  useEffect(() => {
    if (projectInfoEdit && projectInfoEdit._id && projectInfoEdit.start_date) {
      setStartDate(new Date(moment(projectInfoEdit.start_date).format('YYYY-MM-DD')));
      setClientName({ value: projectInfoEdit.client._id, label: projectInfoEdit.client.name })
    }
  }, [projectInfoEdit]);

  return (
    <div className="w-full">
      <div className="mb-12">
        {projectInfoEdit && projectInfoEdit._id ? <><h3 className="font-title text-xl font-medium text-black mb-2">Update Project Basic Info</h3>
        </> : <><h3 className="font-title text-xl font-medium text-black mb-2">Create a New Project</h3>
          <span className="block text-sm text-secondary-grey-200">Step 1: Add Project Info</span></>
        }

      </div>
      <Formik
        initialValues={{
          name: (projectInfoEdit && projectInfoEdit.name) || "",
          client: (projectInfoEdit && projectInfoEdit.client && projectInfoEdit.client._id) || "",
          platform: (projectInfoEdit && projectInfoEdit.platform) || "",
          startDate: (projectInfoEdit && projectInfoEdit.start_date) || "",
          technology: []
        }}

        validateOnBlur={false}

        validationSchema={Yup.object().shape({
          name: Yup.string().required("Enter Project Name"),
          client: Yup.string().test('len', 'Select the Client', val => clientName?.value).nullable(),
          platform: Yup.string().required("Select Platform"),
          startDate: Yup.string().required("Select Date").nullable(),
          technologyCheck: projectInfoEdit && projectInfoEdit._id ? null : Yup.string().required("Select Technology").nullable(),
        })}
        onSubmit={async (values, { resetForm }) => {

          if (projectInfoEdit && projectInfoEdit._id) {
            let payload = {
              name: values.name,
              platform: values.platform,
              start_date: moment(values.startDate).format('YYYY-MM-DD'),
              client: clientName,
              project_id: projectInfoEdit._id
            }
            await UpdateBasicProjectInfo(payload)
              .then(({ project, success }) => {
                if (project) {
                  toast.success("Project updated Successfully");
                }
                if (success) {
                  setClientName('');
                  setStartDate('');
                  setTechnology([]);
                  resetForm();
                  navigate(`/projects/${project.name}`);
                  closeModal();
                }
              }).catch((response) => {
                console.log('response', response);
                if (response.error && response.error.length > 0) {
                  toast.error(response.error);
                }
              });

          } else {

            let payload = {
              name: values.name,
              platform: values.platform,
              start_date: moment(values.startDate).format('YYYY-MM-DD'),
              technologies: technology?.length && technology.map((item) => ({
                _id: item.value,
                type: item.type?.length > 0 ? item.type : '',
                name: item.label
              })),
              client: clientName,
            }
            await createProject(payload)
              .then(({ message, status }) => {
                if (message && message.length > 0) {
                  toast.success(message);
                }
                if (status === 200) {
                  setClientName('');
                  setStartDate('');
                  setTechnology([]);
                  resetForm();
                  nextStep(false);
                  dispatch.projects.listProjectData({
                    currentPage: 0,
                    limit: 10,
                  })
                }
              }).catch((response) => {
                console.log('response', response);
                if (response.error && response.error.length > 0) {
                  toast.error(response.error);
                }
              });

          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className="w-full">
            <div className="mb-8 relative">
              <div>
                <Field
                  className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400"
                  placeholder="Enter Project Name"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                />
              </div>
              {errors.name && touched.name && <span className="text-red-500 text-xs absolute -bottom-5">{errors.name}</span>}
            </div>

            <div className="mb-8 relative">
              <div className="md:flex">
                <div
                  className={`${values.platform === "web"
                    ? "border-primary-navyBlue"
                    : "border-secondary-grey-400"
                    } w-full text-center border rounded-md text-secondary-grey-300 font-normal md:mr-2 mb-4 md:mb-0 h-32 flex justify-center items-center relative transition delay-150 duration-150 ease-linear hover:border-primary-navyBlue`}
                >
                  <div className="">
                    <div className="text-center">
                      <svg
                        className="inline-block"
                        width="42"
                        height="38"
                        viewBox="0 0 42 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.4114 28.3659H3.79026C2.92086 28.3659 2.08708 28.0205 1.47233 27.4058C0.857572 26.791 0.512207 25.9572 0.512207 25.0878V3.54636C0.512207 1.73688 1.98077 0.268311 3.79026 0.268311H38.4439C40.2534 0.268311 41.722 1.73688 41.722 3.54636V25.0878C41.722 25.9572 41.3766 26.791 40.7618 27.4058C40.1471 28.0205 39.3133 28.3659 38.4439 28.3659H26.8228C27.1431 30.9415 28.3307 33.3335 30.1364 35.4034C30.3137 35.6066 30.4287 35.8566 30.4678 36.1234C30.5068 36.3902 30.4682 36.6627 30.3566 36.9082C30.245 37.1537 30.0651 37.3619 29.8384 37.5079C29.6117 37.654 29.3477 37.7317 29.0781 37.7317H13.1561C12.8864 37.7317 12.6225 37.654 12.3957 37.5079C12.169 37.3619 11.9891 37.1537 11.8775 36.9082C11.7659 36.6627 11.7274 36.3902 11.7664 36.1234C11.8055 35.8566 11.9205 35.6066 12.0978 35.4034C13.9035 33.3335 15.0911 30.9415 15.4114 28.3659ZM38.9122 3.54636V25.0878C38.9122 25.212 38.8629 25.3311 38.775 25.419C38.6872 25.5068 38.5681 25.5561 38.4439 25.5561H3.79026C3.66606 25.5561 3.54694 25.5068 3.45912 25.419C3.3713 25.3311 3.32196 25.212 3.32196 25.0878V3.54636C3.32196 3.42216 3.3713 3.30305 3.45912 3.21523C3.54694 3.1274 3.66606 3.07807 3.79026 3.07807H38.4439C38.5681 3.07807 38.6872 3.1274 38.775 3.21523C38.8629 3.30305 38.9122 3.42216 38.9122 3.54636ZM23.9961 28.3659C24.2303 30.7373 25.0526 32.9458 26.287 34.922H15.9471C17.1816 32.9458 18.002 30.7373 18.238 28.3659H23.9961Z"
                          fill="#B6BABD"
                        />
                      </svg>
                    </div>
                    <Field
                      type="radio"
                      name="platform"
                      value="web"
                      className="absolute w-full h-full top-0 left-0 opacity-0 cursor-pointer"
                    />
                    <h6 className="text-sm mt-2">Web App</h6>
                  </div>
                </div>
                <div
                  className={`${values.platform === "mobile"
                    ? "border-primary-navyBlue"
                    : "border-secondary-grey-400"
                    } w-full text-center border rounded-md text-secondary-grey-300 font-normal  h-32 md:ml-2 flex justify-center items-center relative transition delay-150 duration-150 ease-linear hover:border-primary-navyBlue`}
                >
                  <div>
                    <div>
                      <svg
                        className="inline-block"
                        width="22"
                        height="32"
                        viewBox="0 0 22 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.8571 0H2.28565C1.02494 0 -6.10352e-05 1.025 -6.10352e-05 2.28571V29.7143C-6.10352e-05 30.975 1.02494 32 2.28565 32H18.8571C20.1178 32 21.1428 30.975 21.1428 29.7143V2.28571C21.1428 1.025 20.1178 0 18.8571 0ZM18.5714 29.4286H2.57137V2.57143H18.5714V29.4286ZM9.1428 25.7857C9.1428 26.1646 9.29331 26.528 9.56122 26.7959C9.82913 27.0638 10.1925 27.2143 10.5714 27.2143C10.9502 27.2143 11.3136 27.0638 11.5815 26.7959C11.8494 26.528 11.9999 26.1646 11.9999 25.7857C11.9999 25.4068 11.8494 25.0435 11.5815 24.7756C11.3136 24.5077 10.9502 24.3571 10.5714 24.3571C10.1925 24.3571 9.82913 24.5077 9.56122 24.7756C9.29331 25.0435 9.1428 25.4068 9.1428 25.7857Z"
                          fill="#B6BABD"
                        />
                      </svg>
                    </div>

                    <Field
                      type="radio"
                      name="platform"
                      value="mobile"
                      className="absolute w-full h-full top-0 left-0  opacity-0 cursor-pointer"
                    />

                    <h6 className="text-sm mt-2">Mobile App</h6>
                  </div>
                </div>
              </div>
              {errors.platform && touched.platform && <span className="text-red-500 text-xs absolute -bottom-5">{errors.platform}</span>}
            </div>

            <div className="mb-8 relative">
              <CreatableSelect
                defaultValue={projectInfoEdit && projectInfoEdit.client ? { value: projectInfoEdit.client._id, label: projectInfoEdit.client.name } : ""}
                className='pl-0 custom-search-select custom-search-create appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400'
                name="client"
                //value={clientName}
                isSearchable={true}
                isClearable={true}
                classNamePrefix="dropdownSelect"
                options={client.map((project) => ({ label: project.name, value: project._id }))}
                placeholder="Client Name"
                onChange={(e) => {
                  setClientName(e);
                }}
              />
              {errors.client && touched.client && <span className="text-red-500 text-xs absolute -bottom-5">{errors.client}</span>}
            </div>

            <div className="mb-8 relative">
              <label>
                <DatePicker className="appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md py-3 px-4  w-full text-secondary-grey-300 font-normal border-secondary-grey-400"
                  name="startDate"
                  placeholderText="Start Date"
                  selected={startDate}
                  onBlur={false}
                  classNamePrefix="dropdownSelect"
                  dateFormat="dd-MM-yyyy"
                  onChange={(date) => {
                    setStartDate(date)
                    setFieldValue('startDate', date)
                  }} />
                <span className="absolute right-4 top-4 z-0">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.04708 0.0437498C2.90958 0.106249 2.78771 0.231247 2.71897 0.381245C2.66272 0.503119 2.65647 0.590617 2.65647 1.25623V1.99997H2.16899C1.59714 1.99997 1.41902 2.03747 1.04716 2.2281C0.709672 2.40309 0.403435 2.70934 0.228442 3.04684C-0.0184232 3.52495 0.000326034 3.05309 0.000326034 8.99988C0.000326034 14.9467 -0.0184232 14.4748 0.228442 14.9529C0.403435 15.2904 0.709672 15.5967 1.05028 15.7748C1.51901 16.0154 0.972161 15.9998 8 15.9998C15.0278 15.9998 14.481 16.0154 14.9497 15.7748C15.2903 15.5967 15.5966 15.2904 15.7716 14.9529C16.0184 14.4748 15.9997 14.9467 15.9997 8.99988C15.9997 3.05309 16.0184 3.52495 15.7716 3.04684C15.5966 2.70934 15.2903 2.40309 14.9528 2.2281C14.581 2.03747 14.4029 1.99997 13.831 1.99997H13.3435V1.25623C13.3435 0.521869 13.3435 0.509369 13.2654 0.353121C13.2154 0.253121 13.1373 0.159373 13.056 0.106249C12.9279 0.015625 12.9248 0.015625 12.3717 0.00625038C11.7592 -0.00625038 11.678 0.0124998 11.4936 0.209373C11.3342 0.37812 11.3124 0.503119 11.3124 1.28436V1.99997H8H4.68763V1.29373C4.68763 0.521869 4.67201 0.412495 4.52514 0.234372C4.35015 0.0249996 4.25953 0 3.6658 0C3.30019 0.00312519 3.1127 0.015625 3.04708 0.0437498ZM14.6497 10.4436L14.6404 14.1717L14.5529 14.3186C14.4966 14.4154 14.4154 14.4967 14.3185 14.5529L14.1716 14.6404H8H1.82838L1.68151 14.5529C1.58464 14.4967 1.50339 14.4154 1.44714 14.3186L1.35965 14.1717L1.35027 10.4436L1.34402 6.71866H8H14.656L14.6497 10.4436Z"
                      fill="#B6BABD"
                    />
                  </svg>
                </span>
              </label>
              {errors.startDate && touched.startDate && <span className="text-red-500 text-xs absolute -bottom-5">{errors.startDate}</span>}
            </div>

            {
              projectInfoEdit && projectInfoEdit._id ? null :
                <div className="mb-8 relative">
                  <Select
                    className='custom-search-select custom-search-create appearance-none focus:outline-none focus:border-primary-navyBlue transition delay-150 duration-150 ease-linear text-base border rounded-md w-full placeholder-secondary-grey-300 text-secondary-grey-300 font-normal border-secondary-grey-400'
                    name="technology"
                    defaultValue={technology}
                    isMulti
                    isSearchable={true}
                    isClearable={true}
                    options={technologyList.map((tech) => ({ label: tech.name, value: tech._id, type: tech.type }))}
                    placeholder="Technologies"
                    classNamePrefix="dropdownSelect"
                    onChange={(option) => {
                      option?.length > 0
                        ?
                        setFieldValue("technologyCheck", option[0].label, setTechnology(option))
                        : setFieldValue("technologyCheck", "");
                    }}
                  />
                  {errors.technologyCheck && touched.technology ? (
                    <span className="text-red-500 text-xs absolute -bottom-5">{errors.technologyCheck}</span>
                  ) : null}
                </div>

            }

            <button
              disabled={isSubmitting}
              type="submit"
              className="w-full p-4 bg-primary-navyBlue hover:bg-opacity-75 transition delay-150 duration-150 ease-linear text-white rounded-md text-sm font-semibold font-title"
            >
              {
                projectInfoEdit && projectInfoEdit._id ?
                  'Update' : 'Next'

              }
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapState = (state) => ({
  clientProjectsResponseData: state.projects.projectClient,
  technologyResponseData: state.projects.technology,

});

const mapDispatch = (dispatch) => ({
  createProject: dispatch.projects.createProject,
  clientNameSearch: dispatch.projects.clientNameSearch,
  TechnologyNameSearch: dispatch.projects.technologyNameSearch,
  UpdateBasicProjectInfo: dispatch.projectDetails.UpdateBasicProjectInfo
});

export default connect(mapState, mapDispatch)(ProjectCreate);
